import React from 'react';

// Layout
import Layout from '../components/layout';

const LegalPagesTemplate = ({ pageContext: { title, html } }) => {
  return (
    <Layout pageTitle="Adminmate — Cookie Policy">
      <div className="container mx-auto mt-8">
        <div className="py-4 md:py-8 text-center w-5/6 md:w-4/5 mx-auto">
          <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold leading-tight">{title}</h1>
        </div>

        <div className="pb-8 w-5/6 md:w-4/5 mx-auto iubenda-wrapper">
          <div dangerouslySetInnerHTML={{ __html: html }}></div>
        </div>
      </div>
    </Layout>
  );
};

export default LegalPagesTemplate;